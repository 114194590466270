<template>
  <div>
    <div class="znl-grid">
      <div class="znl-header">
             <div slot="from-module">
               <h3 class="subtitle">单据公司代码设置</h3>
       <el-row class="formContainer" type="flex" justify="space-around">
        <el-col >

          <el-row type="flex" class="form_Row_W100">
            <el-col>
              <znl-input
                  title-width="60px"
                  form-type="input"
                  size="mini"
                  layout="left"
                  title="公司代码："
                  width="100%"
                  :clearable="true"
                  max-length=2
                  v-model="CompanyCode"
                  type="text">
              </znl-input>
            </el-col>
            <el-col>
                 <el-button size="mini" type="default" :isShow='true'
                  @click="onSaveCompanyCode(initData)" class="znl-btn-mini">
          <icon class="iconfont icon-save_btn_ic"></icon>保存
        </el-button>
            </el-col>
             <el-col :span="20">

            </el-col>
          </el-row>
        </el-col>
<el-col></el-col>
      </el-row>
    </div>

      </div>
    </div>
    <div class="znl-grid billnorule"
         v-for="(Rule,RuleIndex) in Rules"
         :key="RuleIndex">
       <znl-gridmodule
          :header="Rule.BillTypeName"
          height="300px"
          gridtype="action"
          :columns="columns"
          :item-source="Rule.BillNoRule"
          :on-save-row="onSaveRow"
          checkbox-binding-key="SysBillNoRule"
          :on-refresh="onRefresh "
          :show-delete-row-right-menu="false"
          :users="$store.state.users"
          @edit:beginning="editBegin"
          @edit:cell-ended="(aftervalue, beforvalue, columnname, gridobj, RowIndex, ColIndex)=>{endedCellEdit(aftervalue, beforvalue, columnname, gridobj, RowIndex, ColIndex,RuleIndex)}"
          :onInit="onInitNull">
        </znl-gridmodule>
    </div>
  </div>
</template>

<script>
// 1.公司代码 2.员工代码 3.年 4.月 5.日 6.序号 7.单据代码 8.自定义
const CONFIG = {
  columns: [
    { CTitle: '序号', BindField: 'SerialNo', IsShow: true, IsReadonly: false, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: true, IsRequired: true },
    { CTitle: '规则类型', BindField: 'Remark', IsShow: true, IsReadonly: true, ColumnWidth: 135, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: '占位符', BindField: 'Placeholder', IsShow: true, IsReadonly: true, ColumnWidth: 135, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: '长度', BindField: 'Length', IsShow: true, IsReadonly: false, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: '值', BindField: 'Value', IsShow: true, IsReadonly: false, ColumnWidth: 140, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: '是否启用', BindField: 'State', IsShow: true, IsReadonly: false, ColumnWidth: 140, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: 'RuleID', BindField: 'RuleID', IsShow: false, IsReadonly: true, ColumnWidth: 140, DisplayIndex: 1, DataType: 1, IsSystem: true, IsRequired: true },
    { CTitle: 'RuleType', BindField: 'RuleType', IsShow: false, IsReadonly: true, ColumnWidth: 140, DisplayIndex: 1, DataType: 1, IsSystem: true, IsRequired: true }
  ]
}
export default {
  name: 'SysBillNoRule',
  config: CONFIG,
  components: {
    elOption: Option
  },
  data () {
    return {
      areasize: { minRows: 3, maxRows: 3 },
      config: {
        PK: 'CompanyID',
        saveBillNoRulesURL: 'SysBillNoRule/SaveList',
        searchBillNoRulesURL: 'SysBillNoRule/GetList',
        saveCompanyCodeURL: 'SysBillNoRule/SaveBillNoCompanyCode'
      },
      // columnConfig: [{ ID: 1, property: 'SerialNo', width: 80, label: '序号' },
      // { ID: 2, property: 'Remark', width: 130, label: '规则类型' },
      // { ID: 3, property: 'Placeholder', width: 175, label: '占位符' },
      // { ID: 4, property: 'Length', width: 120, label: '长度' },
      // { ID: 5, property: 'Value', width: 160, label: '值' },
      // { ID: 6, property: 'State', width: 160, label: '是否启用' },
      // { ID: 7, property: 'RuleID', width: 160, label: 'RuleID' }],
      SysBillNoRule: [],
      Rules: [{ BillType: 1, BillTypeName: '客户需求单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 2, BillTypeName: '入库单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 3, BillTypeName: '出库单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 4, BillTypeName: '销售单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 5, BillTypeName: '采购单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 12, BillTypeName: '销售退货单号规则', BillNoRule: [], EnterEdit: false },
      { BillType: 13, BillTypeName: '采购退货单号规则', BillNoRule: [], EnterEdit: false }],
      CompanyCode: '',
      columns: []
    }
  },
  props: {
    loading: Boolean
  },
  mounted () {
    this.initData()
  },
  methods: {
    async onInitNull () {
      return true
    },
    async onRefresh () {
      return true
    },

    initData () {
      let columns = this.flexGridColumnsHandler(this.$options.config.columns)

      if (
        _.find(columns, function (column) {
          return column.binding === 'State'
        })
      ) {
        _.extend(
          _.find(columns, function (column) {
            return column.binding === 'State'
          }),
          {
            dataMap: [{ key: 0, value: '否' }, { key: 1, value: '是' }],
            showDropDown: true
          }
        )
      }
      this.columns = columns
      this.$post(this.config.searchBillNoRulesURL, null, (data) => {
        this.SysBillNoRule = data
        _.each(this.Rules, (item, index) => { item.EnterEdit = false })
        this.initRules()
        // this.columns = columns
      })
    },
    initRules () {
      // 1.需求2.入库 3.出库 4.销售 5采购 6.销售退货单号规则 7.采购退货单号规则
      let data = _.map(this.SysBillNoRule, item => _.clone(item))
      _.each(this.Rules, (billrule, ruleindex) => {
        this.$set(billrule, 'BillNoRule', _.filter(data, (item, index) => { return item.BillType === billrule.BillType }))
        console.log(billrule)
      })
    },
    isNull (val) {
      if (val === null || val === undefined || val === '') {
        return true
      } else {
        return false
      }
    },
    onSaveRow (rows) {
      // console.log('1111', rows)
      return this.$post(this.config.saveBillNoRulesURL, rows, (data) => {
        this.$message({ message: '保存成功', type: 'success' })
        this.initData()
      })
    },
    onSubmit (rulesData, succeed) {
      let vm = this
      return vm.$post(vm.config.saveBillNoRulesURL, rulesData, (data) => {
        vm.$message({ message: '保存成功', type: 'success' })
        this.initData()
        vm.$nextTick(function () {
          succeed()
        })
      })
    },
    onCancel (ruleBillType, succeed) {
      let vm = this
      let data = _.map(this.SysBillNoRule, item => _.clone(item))
      _.each(this.Rules, (billrule, ruleindex) => {
        if (billrule.BillType === ruleBillType) {
          // this.$set(billrule, 'BillNoRule', [])
          console.log(_.filter(data, (item, index) => { return item.BillType === billrule.BillType }))
          this.$set(billrule, 'BillNoRule', _.filter(data, (item, index) => { return item.BillType === billrule.BillType }))
        }
      })

      vm.$nextTick(function () {
        succeed()
      })
    },
    onSaveCompanyCode (succeed) {
      if (this.CompanyCode === undefined || this.CompanyCode === '') {
        return this.$message({ message: '单据代码', type: 'error' })
      }
      let vm = this
      return vm.$post(vm.config.saveCompanyCodeURL, { BillNoCompanyCode: this.CompanyCode }, (data) => {
        if (data) {
          vm.$message({ message: '保存成功', type: 'success' })
          this.initData()
          vm.$nextTick(function () {
            succeed()
          })
        }
      })
    },
    isEffective (val) {
      return !_.isNull(val) && !_.isUndefined(val) && val.length > 0
    },
    sortUp (index, dataArray) {
      const removed = dataArray.splice(index, 1)
      dataArray.splice(index - 1, 0, removed[0])
      dataArray[index].sort = index
      dataArray[index - 1].sort = index - 1
      _.each(dataArray, (item, index) => { item.SerialNo = index + 1 })
      // this.sortIndex = index - 1
    },
    sortDown (index, dataArray) {
      const removed = dataArray.splice(index, 1)
      dataArray.splice(index + 1, 0, removed[0])
      dataArray[index].sort = index
      dataArray[index + 1].sort = index + 1
      _.each(dataArray, (item, index) => { item.SerialNo = index + 1 })
      // this.sortIndex = index + 1
    },
    editBegin (value, colName, grid, rowIndex, colIndex) {
      console.log(value, colName, grid, rowIndex, colIndex)
    },
    endedCellEdit (aftervalue, beforvalue, columnname, gridobj, RowIndex, ColIndex, index) {
      let rowData = gridobj.row
      let ruleType = rowData.RuleType
      if (columnname === 'Value' && ruleType < 7) {
        this.Rules[index]['BillNoRule'][RowIndex]['Value'] = beforvalue
        gridobj.setCellData(RowIndex, ColIndex, beforvalue)
        this.$message({ message: '不允许修改', type: 'error' })
      }
      // console.log('52100')
      // console.log(ruleType)
      // console.log(columnname)
      if (columnname === 'Length' && ruleType === 3) {
        let myDate = new Date()
        let dateStr = myDate.getFullYear() + ''
        if (aftervalue > 3) {
          // console.log(dateStr)
          this.Rules[index]['BillNoRule'][RowIndex]['Value'] = dateStr
          let ruleValueIndex = gridobj.getColumn('Value').index
          gridobj.setCellData(RowIndex, ruleValueIndex, dateStr)
        } else {
          let dateStr1 = (dateStr + '').substr(2, 2)
          this.Rules[index]['BillNoRule'][RowIndex]['Value'] = dateStr1
          let ruleValueIndex = gridobj.getColumn('Value').index
          gridobj.setCellData(RowIndex, ruleValueIndex, dateStr)
        }
      } else if (columnname === 'Length' && ruleType === 6) {
        let valstr = this.pad(1, Number(aftervalue))
        let ruleValueIndex = gridobj.getColumn('Value').index
        this.Rules[index]['BillNoRule'][RowIndex]['Value'] = valstr
        gridobj.setCellData(RowIndex, ruleValueIndex, valstr)
      } else if (columnname === 'Length' && ruleType !== 8) {
        this.Rules[index]['BillNoRule'][RowIndex]['Length'] = beforvalue
        gridobj.setCellData(RowIndex, ColIndex, beforvalue)
        this.$message({ message: '不允许修改', type: 'error' })
      }
    },
    pad (num, n) {
      return Array(n > num ? (n - ('' + num).length + 1) : 0).join(0) + num
    }
  }
}
</script>

<style lang="scss" scoped>
.znl-grid.billnorule {
  margin-bottom: 5px !important;
}
</style>
