<template>
  <!-- <div>
    <div class="znl-grid">
      <div class="znl-header">
        <strong class="znl-header-text">
          <span>流程配置</span>
        </strong>
      </div>
      <div class="znl-btns">
        <el-checkbox  style="margin-left:25px;" size="mini" class="form-group" v-model="PurcharseWorkflow" @change="OnChangePurcharseWorkflow">入库时必须先有采购订单</el-checkbox>
        <el-checkbox  style="margin-left:25px;" size="mini" class="form-group" v-model="SalesWorkflow" @change="OnChangeSalesWorkflow">出库时必须先有销售订单</el-checkbox>
        <el-checkbox  style="margin-left:25px;" size="mini" class="form-group" v-model="AuditWorkflow" @change="OnChangeAuditWorkflow">启用订单审核流程</el-checkbox>
        <el-checkbox  style="margin-left:25px;" size="mini" class="form-group" v-model="WarehouseWorkflow" @change="OnChangeWarehouseWorkflow">启用仓库权限设置</el-checkbox>
      </div>
          <div class="znl-content">
         </div>
    </div>

  </div> -->
  <znl-gridmodule ref="flexGrid"
        header="流程配置"
        gridtype="base"
        :columns="columns"
        height="100%"
        :has-znl-actions="true"
        :item-source="itemSource"
        :on-init="onInit"
        :is-init="false"
        :users="$store.state.users"
        @page-loading="d=>{$emit('page-loading', d)}"
        >
        <div slot="action-form">
          <!-- <el-checkbox   size="mini" class="form-group" v-model="PurcharseWorkflow" @change="OnChangePurcharseWorkflow">入库时必须先有采购订单</el-checkbox>
          <el-checkbox   size="mini" class="form-group" v-model="SalesWorkflow" @change="OnChangeSalesWorkflow">出库时必须先有销售订单</el-checkbox> -->
          <el-checkbox   size="mini" class="form-group" v-model="AuditWorkflow" @change="OnChangeAuditWorkflow">启用订单审核流程</el-checkbox>
          <el-checkbox   size="mini" class="form-group" v-model="WarehouseWorkflow" @change="OnChangeWarehouseWorkflow">启用仓库权限设置</el-checkbox>
      </div>
  </znl-gridmodule>
</template>

<script>
const CONFIG = {
  PK: 'CompanyID',
  getSysWorkflowSetUrl: 'SysWorkflowSet/GetSysWorkflowSet',
  saveSysWorkflowSetUrl: 'SysWorkflowSet/SaveSysWorkflowSet',
  getWarehouseJurisdiction: 'CompanyParamSetup/GetWarehouseJurisdictionParam',
  saveWarehouseJurisdiction: 'CompanyParamSetup/AddWarehouseJurisdiction',
  columns: [
    { CTitle: 'ID', BindField: 'ID', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: 'CreatedByName', BindField: 'CreatedByName', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: 'CreatedTime', BindField: 'CreatedTime', IsShow: true, IsReadonly: true, ColumnWidth: 160, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: 'IsMust', BindField: 'IsMust', IsShow: true, IsReadonly: true, ColumnWidth: 100, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
    { CTitle: 'WorkflowType', BindField: 'WorkflowType', IsShow: true, IsReadonly: true, ColumnWidth: 80, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true }
  ]
}
export default {
  name: 'SysWorkflowSet',
  config: CONFIG,
  components: {
  },
  data () {
    return {
      PurcharseWorkflow: false,
      SalesWorkflow: false,
      AuditWorkflow: false,
      WarehouseWorkflow: false,
      columns: [],
      itemSource: []
    }
  },
  props: {
    loading: Boolean
  },
  mounted () {
    // this.onInit()
  },
  methods: {
    onInit: async function () {
      // console.log('初始化')
      // let columns = this.flexGridColumnsHandler(this.$options.config.columns)
      let config = await this.$post(CONFIG.getSysWorkflowSetUrl)
      // console.log(config)
      if (config !== undefined || config.length > 0) {
        config.forEach(function (element) {
          // if (element.WorkflowType === 1) {
          //   if (element.IsMust) { this.PurcharseWorkflow = true } else { this.PurcharseWorkflow = false }
          // }
          // if (element.WorkflowType === 2) {
          //   if (element.IsMust) { this.SalesWorkflow = true } else { this.SalesWorkflow = false }
          // }
          if (element.WorkflowType === 3) {
            if (element.IsMust) { this.AuditWorkflow = true } else { this.AuditWorkflow = false }
          }
        }, this)
      }
      // this.columns = columns
      // this.itemSource = config
      let companyConfig = await this.$post(CONFIG.getWarehouseJurisdiction)
      if (companyConfig) {
        if (companyConfig.SValue === '1') {
          this.WarehouseWorkflow = true
        }
      }
    },
    OnChangePurcharseWorkflow () {
      let saveData = {
        WorkflowType: 1,
        IsMust: this.PurcharseWorkflow
      }
      return this.$post(CONFIG.saveSysWorkflowSetUrl, saveData, (data) => {
        if (data > 0) {
          this.$message({ message: '设置成功', type: 'success' })
        } else {
          this.$message({ message: '设置失败', type: 'error' })
        }
      })
    },
    OnChangeSalesWorkflow () {
      let saveData = {
        WorkflowType: 2,
        IsMust: this.SalesWorkflow
      }
      return this.$post(CONFIG.saveSysWorkflowSetUrl, saveData, (data) => {
        if (data > 0) {
          this.$message({ message: '设置成功', type: 'success' })
        } else {
          this.$message({ message: '设置失败', type: 'error' })
        }
      })
    },
    OnChangeAuditWorkflow () {
      let saveData = {
        WorkflowType: 3,
        IsMust: this.AuditWorkflow
      }
      return this.$post(CONFIG.saveSysWorkflowSetUrl, saveData, (data) => {
        if (data > 0) {
          this.$message({ message: '设置成功', type: 'success' })
        } else {
          this.$message({ message: '设置失败', type: 'error' })
        }
      })
    },
    OnChangeWarehouseWorkflow () {
      let svalue = this.WarehouseWorkflow ? '1' : '0'
      // console.log(svalue)
      return this.$post(CONFIG.saveWarehouseJurisdiction, { SValue: svalue }, (data) => {
        if (data > 0) {
          this.$message({ message: '设置成功', type: 'success' })
        } else {
          this.$message({ message: '设置失败', type: 'error' })
        }
      })
    }
  },
  created () {
    this.onInit()
  }
}
</script>

<style>
</style>
