<template>
 <znl-gridmodule ref="flexGrid"
        header="查价员配置"
        gridtype="action"
        :columns="columns"
        height="100%"
        :grid-show="gridShow"
        :has-znl-actions="false"
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-fields-search="true"
        :is-multi-rows-check="true"
        :search-fields="searchModelCom"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :users="$store.state.users"
        :show-save-row-right-menu="showSaveRowRightMenu"
        :show-add-row-right-menu="showAddRowRightMenu"
        :show-delete-row-right-menu="showDeleteRowRightMenu"
        :on-init="onInit"
        :is-init="false"
        :on-refresh="onRefresh"
        :on-search="onSearch"
        :on-before-add-row="onBeforeAddRow"
        :on-save-row="onSaveRows"
        :on-save-rows="onSaveRows"
        :on-delete-row="onDelete"
        :on-delete-rows="onDelete"
        :on-save-setting="onSaveSetting"
        :on-reset-setting="onResetSetting"
        :on-page-changed="onBindData"
        :on-save-config='onSaveConfig'
        @page-loading="d=>{$emit('page-loading', d)}"
        @selection-changed="d=>{$emit('selection-changed',d)}"
        checkboxBindingKey="CID">
     <div slot="heading-slot">
      <znl-button style-type="mac"  :height='22' @click="onAddRow()" v-if="this.hasRes('Add')">
        <span> <i class="iconfont icon-add_btn_ic"></i>新增</span>
      </znl-button>
      <znl-button style-type="mac" :height='22' @click="onDelete()" v-if="this.hasRes('Delete')">
        <span> <i class="iconfont icon-delete_btn_ic"></i> 删除</span>
      </znl-button>
   </div>
    <div>
     <user-select
        :visible='userSelectVisible'
        v-if="userSelectVisible"
        :check-model="checkModel"
        :title="title"
        :savetype="check"
        @save-success="onRefresh"
        @close="()=>{userSelectVisible=false}"
        @update:visible="(val)=>{userSelectVisible=val}"
      >
      </user-select>
    </div>
 </znl-gridmodule>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import UserSelect from '@c_modules/Account/UserSelect'

const CONFIG = {
  PK: 'CID',
  configURL: 'BomCheckUser/GetConfig',
  saveConfigURL: 'BomCheckUser/SaveConfig',
  resetConfigURL: 'BomCheckUser/ResetConfig',
  searchURL: 'BomCheckUser/Search',
  saveRowsURL: 'BomCheckUser/BatchSave',
  deleteURL: 'BomCheckUser/BatchDelete'
}

export default {
  name: 'RoleManage',
  mixins: [znlMethodsMixin, getCommonDataMixin],
  config: CONFIG,
  components: {
    UserSelect
  },
  props: {
    initData: {
      type: Boolean,
      default: true
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: String
  },
  data () {
    return {
      check: '1',
      title: '选择查价员',
      gridShow: false,
      columns: [],
      itemSource: [],
      searchFields: {},
      searchModelCom: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      showSaveRowRightMenu: false,
      showAddRowRightMenu: false,
      showDeleteRowRightMenu: false,
      resourceList: [],
      userSelectVisible: false
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('bomCheckUser', CONFIG.configURL)
        // config = await this.$post(CONFIG.configURL)
      }
      this.resourceList = config.ResourceList.map(item => item.Code)
      let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      _.each(columns, column => {
        if (column.binding === 'BuyEmpName') {
          column.buttons = [
            {
              'content': (row) => {
                if (!_.isUndefined(row.CID) && row.CID > 0 && this.hasValue(row.BuyEmpName) &&
                  this.hasRes('SetAuthor')
                ) {
                  return row.BuyEmpName
                } else if (!_.isUndefined(row.CID) && row.CID > 0 && !this.hasValue(row.BuyEmpName)) {
                  return '配置查价员'
                } else {
                  return '配置查价员'
                }
              },
              'className': 'znl-table-btn-success',
              'when': this.hasRes('SetAuthor'),
              'click': (row) => {
                this.setUser(row)
              }
            }
          ]
        }
      })
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })
      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'UpdateByName'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      this.defaultConfig = config
      this.columns = columns
      this.role = config.Role
      this.pageSize = config.PageSize
      this.showAddRowRightMenu = this.hasRes('Add')
      this.showDeleteRowRightMenu = this.hasRes('Delete')
      this.showSaveRowRightMenu = this.hasRes('Add')
      this.initData && this.onBindData()
    },
    hasValue (val) {
      if (val === null || val === undefined || val === '') {
        return false
      }
      return true
    },
    onSearch (params) {
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    onBindData (pageIndex = 1) {
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
      })

      return this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.gridShow = true
      })
    },
    onBeforeAddRow: async function () {
      let nullPK = {}
      nullPK[CONFIG.PK] = null
      return _.extend({}, nullPK, { IsActive: 2 })
    },
    deleteCheckedRows (data) {
      _.each(this.$el.querySelectorAll('.znl-addNewRow'), item => {
        item.classList.remove('znl-addNewRow')
      })
      _.each(this.itemSource, (item, i) => {
        if (item && data && _.isEqual(this.$refs.flexGrid.transformSubmitValue(item), this.$refs.flexGrid.transformSubmitValue(data))) {
          this.itemSource.splice(i, 1)
          return
        }
      })
    },
    onAddRow () {
      this.$refs.flexGrid.addRow()
    },

    onDelete () {
      var rows = this.$refs.flexGrid.getSelectedRows()
      if (rows.length === 1 && rows[0]['_PKIndex'] === -1) {
        return this.itemSource.shift(rows[0])
      }
      if (_.isUndefined(rows) || _.isUndefined(rows[0])) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      let lists = []
      _.each(rows, item => {
        if (item.CID && !_.isUndefined(item.CID) && item.CID > 0) {
          lists.push(item.CID)
        }
      })
      if (lists.length === 0) {
        return this.$message({ message: '请至少选择一条数据进行操作', type: 'error' })
      }
      this.$confirm('确认删除' + lists.length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.$post(CONFIG.deleteURL, { CIDs: lists }, (data) => {
          if (data > 0) {
            this.$refs.flexGrid.clearSelection()
            this.$message({ message: '删除成功', type: 'success' })
            this.onSearch()
          } else {
            this.$message({ message: '删除失败', type: 'error' })
          }
        })
      })
    },
    hasRes (code) {
      return _.includes(this.resourceList, code)
    },
    onSaveRows (rows) {
      if (this.saveCheck(rows) === true) {
        let res = this.$post(CONFIG.saveRowsURL, rows, (data, logic) => {
          this.$refs.flexGrid.clearSelection()
          if (logic.code === 200) {
            this.$message({ message: '保存成功', type: 'success' })
            this.$refs.flexGrid.$refs.actionFlexGrid.saveScueess()
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
        })
        return res
      }
    },
    saveCheck (rows) {
      if (_.isUndefined(rows) || rows.length === 0) {
        this.$message({ message: '表格未做更改', type: 'error' })
        return false
      }
      let result = true
      _.each(rows, item => {
        if (item.pu === '') {
          result = false
          this.$message({ message: '请输入产品类型', type: 'warn' })
          return true
        }
      })

      return result
    },
    async onSaveSetting (columns, pageSize) {
      localStorage.removeItem('bomCheckUser')
      await this.$post(this.$options.config.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, this.defaultConfig))
    },
    async onResetSetting () {
      localStorage.removeItem('bomCheckUser')
      await this.$post(this.$options.config.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig (column) {
      localStorage.removeItem('bomCheckUser')
      return this.$post(
        this.$options.config.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, this.defaultConfig)
      )
    },
    setUser (row) {
      this.checkModel = row
      this.userSelectVisible = true
      this.$refs.flexGrid.clearSelection()
    }
  }
}
</script>

<style lang="scss">
</style>
