<template>
    <!-- <div>
        <sys-workflow-set v-if='hasRight("SysWorkflowSet")'></sys-workflow-set>
    </div> -->
    <znl-layout-spa :page-loading="$store.state.pageLoading && pageLoading">
          <sys-workflow-set
            slot="znlLayoutSpa"
            class="znlLayoutSpa"

            v-if='hasRight("SysWorkflowSet")'></sys-workflow-set>
    </znl-layout-spa>
</template>

<script>
import SysWorkflowSet from '@c_modules/Sys/SysWorkflowSet'
import Icon from 'vue-awesome/components/Icon.vue'

export default {
  name: 'VSysWorkflowSet',
  components: {
    icon: Icon,
    SysWorkflowSet
  },
  data () {
    return {
      pageData: [],
      pageLoading: false
    }
  },
  methods: {
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    getPageData: async function () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      let retData = await this.$post(pageUrl, {PageCode: 'VSysWorkflowSet'})
      this.pageData = retData
    }
  },
  async created () {
    await this.getPageData()
  }
}
</script>

<style lang="scss" scoped>

</style>
