<template>
    <div>
        <SysBillNoRule v-if='hasRight("SysBillNoRule")' :searchModel="crmTerminalCustomerListingSearchModel" @selection-changed = "customersSelectionChange" ></SysBillNoRule>
    </div>

</template>

<script>
import { Button, Input } from 'element-ui'
import SysBillNoRule from '@/components/modules/Sys/SysBillNoRule'
import Icon from 'vue-awesome/components/Icon.vue'

export default {
  name: 'VSysBillNoRule',
  components: {
    elButton: Button,
    elInput: Input,
    icon: Icon,
    SysBillNoRule
  },
  data () {
    return {
      crmTerminalCustomerListingSearchModel: {},
      CCompanyID: '',
      crmCompanyContactsSearchModel: {},
      crmCompanyFollowInfoSearchModel: {},
      crmCompanyFollowInfoCustomerModel: {},
      crmBomSearchModel: {},
      crmBomCustomerModel: {},
      stkOutSearchModel: {},
      stkOutCustomerModel: {},
      pageData: []
    }
  },
  methods: {
    customersSelectionChange (item) {

    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    },
    getPageData: async function () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      let retData = await this.$post(pageUrl, {PageCode: 'VSysBillNoRule'})
      this.pageData = retData
    }
  },
  async created () {
    await this.getPageData()
  }
}
</script>

<style lang="scss" scoped>
</style>
